
  import { Component, Watch } from 'vue-property-decorator'
  import { plainToInstance } from 'class-transformer'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import { Form } from '@/entities/public/Resource/metadata'
  import { fixAmountFormatted, isValidNumber, stringifySafe } from '@/utils/general'

  import LinkedPerson from '@/components/forms/fields/LinkedPerson.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import GRadioButton from '@/components/core/input/GRadioButton.vue'

  import { GForm } from '@/components/forms/GForm'
  import { PublishPlatform, SocialNetworks, Stock } from '@/entities/sales'
  import GCostField from '@/components/core/input/GCostField.vue'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import AutoResume from '@/components/forms/AutoResume.vue'
  import { ComponentCategory, Generation } from '@/entities/vehicle'
  import { mapActions } from 'vuex'

@Component({
  components: { AutoResume, GAlert, GCostField, GRadioButton, GFiles, LinkedPerson, BaseForm },
  methods: {
    ...mapActions('resources', ['removeStock', 'excludeStock']),
    fixAmountFormatted,
  },
  computed: {},
})
  export default class AdvertiserPublishStockForm extends GForm {
  excludeStock!: ({ idStock, exclude }: { idStock: number, exclude: boolean }) => Promise<void>

  publishPlatforms: PublishPlatform[] = []

  stock: Stock = plainToInstance(Stock, {})
  message = ''
  ready = false
  exclude = ['Llantas de aleación', 'Tapas de ruedas', 'Pernos de seguridad']
  idProcess = null
  isUploadingFile = false
  generation = null
  idPlatform = null
  idProcessVersion = null
  idChileautos = null
  formDataEquipment = {
    comfort: null,
    security: null,
    external: null,
  }

  itemsComfort = []
  itemsSecurity = []
  itemsExternal = []
  attributes = []
  equipmentComfort = null
  equipmentSecurity = null
  equipmentExternal = null
  fileParametersVideo = []
  componentKeysVideo = []
  fileParametersPhoto = []
  equipment: ComponentCategory[] = []
  componentKeysPhoto = []
  processInspectionId = null
  showDetail = false
  categoryComponentMaintenance = null
  categoryComponentIdentification = null
  priceList = null
  title = ''
  formSocialNetworks: Record<string, any> = {}
  networksKeys = []
  formData: Record<string, any> = {}
  errorMessages: Record<string, string> = {}
  fields: Record<string, any> = {}
  aliasChileautos: string = ''
  metadata = {}
  metadataCollection = {}
  alert = {
    open: false,
    title: '',
  }

  get change () {
    const { formData } = this

    return stringifySafe([formData])
  }

  get stockHasPrice () {
    const { stock } = this

    return Boolean(stock?.currentPrice?.amount)
  }

  get stockHasPhotos () {
    const { formData, fileParametersVideo, fileParametersPhoto } = this
    let hasFiles = true

    const keyPhoto = fileParametersPhoto.filter(file => file.required).map(file => file.description)
    const keyVid = fileParametersVideo.filter(file => file.required).map(file => file.description)

    for (const key of [...keyPhoto, ...keyVid]) {
      if (!formData?.[key]?.photo?.length) {
        hasFiles = false
      }
    }

    return hasFiles
  }

  get stockForPublish () {
    const { stockHasPrice, stockHasPhotos } = this

    return stockHasPrice && stockHasPhotos
  }

  async mounted () {
    await this.setMetadata()
    const { uid, id, title, metadataCollection } = this
    const enablementId = uid || id

    if (isValidNumber(enablementId)) {
      await this.getStockInfo(enablementId)
    }

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(metadataCollection, title, Boolean(enablementId))
    }

    this.equipment = (await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Equipamiento' } },
    }))[0]

    this.generation = await this.getAutoGeneration(this.stock.auto)

    await this.getPublishPlatforms()
    this.displayDetail()
  }

  async getPublishPlatforms () {
    const platforms = await this.fetchData({
      query: { name: 'find', model: 'Platform' },
      filter: {
        _and: [
          { name_platform_type: { _eq: 'publication' } },
          { active: { _eq: true } },
        ],
      },
    })

    const stockPlatforms = this.stock.metadata.publishPlatforms || []

    this.publishPlatforms = platforms
      .sort((prev, next) => {
        return prev.name < next.name ? -1 : 1
      })
      .map(portal => ({
        id: portal.id,
        name: portal.name,
        enabled: stockPlatforms.length ? stockPlatforms?.find(platform => platform.name === portal.name)?.enabled : true,
      }))
  }

  get comfortEquipment () {
    const { equipment } = this

    return equipment?.components?.find(component => component.slug === 'comfort_equipment')
  }

  get securityEquipment () {
    const { equipment } = this

    return equipment?.components?.find(component => component.slug === 'security_equipment')
  }

  get externalEquipment () {
    const { equipment } = this

    return equipment?.components?.find(component => component.slug === 'aesthetic_equipment')
  }

  get dataConsolidatedComfort () {
    const { comfortEquipment, generation } = this

    return {
      equipment: comfortEquipment,
      generation,
    }
  }

  @Watch('dataConsolidatedComfort', { immediate: true, deep: true })
  async onComfortChange (val) {
    if (!val?.generation || !val?.equipment) return null

    const { equipmentComfort, exclude, comfortEquipment, idProcess, stock } = this

    const attributes = await this.getDealAutoAttribute(comfortEquipment?.id, idProcess, stock.id) || []

    if (attributes.length) {
      this.attributes.push(...attributes?.map(attr => attr.componentValue?.id))
    }

    if (equipmentComfort?.present.length && equipmentComfort.extra?.length) return null
    const { present, extra } = this.separateValues(val, 'Equipamiento comfort')

    this.equipmentComfort = {
      present: present.filter(item => !exclude.includes(item.value)),
      extra: extra.filter(item => !exclude.includes(item.value)),
    }
  }

  get dataConsolidateSecurity () {
    const { securityEquipment, generation } = this

    return {
      equipment: securityEquipment,
      generation,
    }
  }

  @Watch('dataConsolidateSecurity', { immediate: true, deep: true })
  async onSecurityChange (val) {
    if (!val?.generation || !val?.equipment) return null
    const { equipmentSecurity, exclude, securityEquipment, idProcess, stock } = this
    const attributes = await this.getDealAutoAttribute(securityEquipment?.id, idProcess, stock.id) || []

    if (attributes.length) {
      this.attributes.push(...attributes?.map(attr => attr.componentValue?.id))
    }

    if (equipmentSecurity?.present.length && equipmentSecurity.extra?.length) return null
    const { present, extra } = this.separateValues(val, 'Equipamiento seguridad')

    this.equipmentSecurity = {
      present: present.filter(item => !exclude.includes(item.value)),
      extra: extra.filter(item => !exclude.includes(item.value)),
    }
  }

  get dataConsolidateExternal () {
    const { externalEquipment, generation } = this

    return {
      equipment: externalEquipment,
      generation,
    }
  }

  @Watch('dataConsolidateExternal', { immediate: true, deep: true })
  async onExternalChange (val) {
    if (!val?.generation || !val?.equipment) return null
    const { equipmentExternal, exclude, externalEquipment, stock, idProcess } = this
    const attributes = await this.getDealAutoAttribute(externalEquipment?.id, idProcess, stock.id) || []

    if (attributes.length) {
      this.attributes.push(...attributes?.map(attr => attr.componentValue?.id))
    }

    if (equipmentExternal?.present.length && equipmentExternal.extra?.length) return null
    const { present, extra } = this.separateValues(val, 'Equipamiento exterior')

    this.equipmentExternal = {
      present: present.filter(item => !exclude.includes(item.value)),
      extra: extra.filter(item => !exclude.includes(item.value)),
    }
  }

  get attributedConsolidated () {
    const { equipmentComfort, equipmentExternal, equipmentSecurity, attributes } = this

    return {
      equipmentComfort,
      equipmentExternal,
      equipmentSecurity,
      attributes,
    }
  }

  @Watch('attributedConsolidated', { immediate: true, deep: true })
  onAttributesChange (val) {
    // TODO: cuando la BD lo acomode quitar la velocidad crucero
    const extraComfort = val.equipmentComfort?.extra?.filter(item => item.value !== 'Velocidad crucero')
    this.formDataEquipment.comfort = val.equipmentComfort?.extra.filter(item => val.attributes.includes(item.id))
    const extraSecurity = val.equipmentSecurity?.extra
    this.formDataEquipment.security = val.equipmentSecurity?.extra.filter(item => val.attributes.includes(item.id))
    const extraExterior = val.equipmentExternal?.extra
    this.formDataEquipment.external = val.equipmentExternal?.extra.filter(item => val.attributes.includes(item.id))

    this.itemsComfort = extraComfort
    this.itemsSecurity = extraSecurity
    this.itemsExternal = extraExterior
  }

  async setMetadata () {
    const { metadata } = this.getForm('Stock', 'advertiser_publish_stock')
    const { form } = metadata as Form
    this.title = form.title
    this.metadataCollection = metadata
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'stock' } },
    })

    this.idProcess = process[0].id
    const photos = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { _and: [{ process: { table_name: { _eq: 'stock' } } }, { file_type: { name: { _eq: 'photo' } } }] },
    })

    this.fileParametersPhoto = photos.sort((a, b) => a.visibility?.form?.order - b?.visibility?.form?.order)
    this.componentKeysPhoto = this.fileParametersPhoto.map(fileParameter => fileParameter.description)

    const videos = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { _and: [{ process: { table_name: { _eq: 'stock' } } }, { file_type: { name: { _eq: 'video' } } }] },
    })

    this.fileParametersVideo = videos.sort((a, b) => a.visibility?.form?.order - b.visibility?.form?.order)
    this.componentKeysVideo = this.fileParametersVideo.map(fileParameter => fileParameter.description)

    this.generateFormInfo([...this.fileParametersPhoto, ...this.fileParametersVideo])
    this.generateSocialNetworksForm(process?.[0]?.config?.socialNetworks)
  }

  generateSocialNetworksForm (networks) {
    const keys = Object.keys(networks)
    for (const key of keys) {
      if (networks[key]) {
        this.networksKeys.push(key)
        this.$set(this.formSocialNetworks, key, { url: '' })
      }
    }
  }

  generateFormInfo (components) {
    for (const field of components) {
      const fieldKey = field.description

      this.$set(this.formData, fieldKey, { photo: [] })
      this.$set(this.errorMessages, fieldKey, '')
      this.$set(this.fields, fieldKey, {
        properties: {
          accept: '',
          multiple: false,
          fileTypeId: null,
          name: '',
          label: field.description,
        },
      })

      this.setProperties(field, fieldKey)
    }
    this.ready = true
  }

  setProperties (fileInfo, fieldKey) {
    const info = fileInfo
    if (info) {
      this.fields[fieldKey].properties.accept = info.fileType.mimes
      this.fields[fieldKey].properties.multiple = info.multiple
      this.fields[fieldKey].properties.fileTypeId = info.fileType.id
      this.fields[fieldKey].properties.name = info.name
      this.fields[fieldKey].properties.required = info.required
      this.fields[fieldKey].properties.label = info.description
    }
  }

  async getStockInfo (id) {
    this.stock = await this.fetchData({
      query: { name: 'fetch', model: 'StockAdvertiser', params: { id } },
      force: true,
    })

    if (this.stock.metadata?.socialNetworks) {
      this.setSocialNetworksForm(this.stock.metadata?.socialNetworks)
    }

    const processVersion = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'version' } },
    })
    this.idProcessVersion = processVersion?.[0]?.id

    const enterpriseChileautos = (await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { alias: { _ilike: '%Chileautos%' } },
    }))[0]
    this.idChileautos = enterpriseChileautos?.id

    const alias = (await this.fetchData({
      query: { name: 'find', model: 'Alias' },
      filter: {
        _and: [
          { id_process_record: { _eq: this.stock.auto.version.version.id } },
          { id_person: { _eq: this.idChileautos } },
          { id_process: { _eq: this.idProcessVersion } },
        ],
      },
      force: true,
    }))[0]

    this.aliasChileautos = alias?.name

    const platForm = (await this.fetchData({
      query: { name: 'find', model: 'Platform' },
      filter: { name: { _ilike: '%chileautos%' } },
    }))[0]

    this.idPlatform = platForm?.id
  }

  setSocialNetworksForm (networks) {
    const keys = Object.keys(networks)
    for (const key of keys) {
      if (networks[key]?.url) {
        this.formSocialNetworks[key].url = networks[key].url
      }
    }
  }

  displayDetail () {
    const { stock, metadataCollection } = this

    this.metadata = {
      data: stock,
      metadata: metadataCollection,
    }

    this.showDetail = Boolean(stock.id)
  }

  async send () {
    if (!this.$refs.form.validate()) {
      return
    }
    this.checkForDuplicatePhotos()
    const hasErrors = Object.values(this.errorMessages).some(message => message !== '')
    if (hasErrors) {
      return
    }

    this.loadingForm = true
    await this.sendPhotos()
    await this.sendStockMetadata()
    await this.sendExtra()
    await this.excludeStock({ idStock: this.stock.id, exclude: false })

    if (this.stock.metadata?.publishPlatforms?.every(platform => platform.enabled)) {
      await this.publishStock(this.stock.id)
    } else {
      const { publishPlatforms } = this.stock.metadata

      await Promise.all((publishPlatforms || []).map(async platform => {
        if (platform.enabled) {
          await this.publishByPlatform({
            id_stock: this.stock.id,
            id_platform: platform.id,
          })
        }
      }))
    }

    await this.updateAlias()
    this.loadingForm = false

    await this.close()
  }

  async deleteAlias () {
    const alias = (await this.fetchData({
      query: { name: 'find', model: 'Alias' },
      filter: {
        _and: [
          { id_process_record: { _eq: this.stock.auto?.version.version?.id } },
          { id_person: { _eq: this.idChileautos } },
          { id_process: { _eq: this.idProcessVersion } },
        ],
      },
      force: true,
    }))[0]
    if (alias?.id) {
    this.removeData({
        model: 'Alias',
        fields: { id: alias.id },
      })
    }
    this.aliasChileautos = ''
    this.businessRepublishById({ idStock: this.stock.id, idPlatform: this.idPlatform })
  }

  async updateAlias () {
    const { aliasChileautos, stock } = this
    if (!aliasChileautos?.length) return

    const alias = (await this.fetchData({
      query: { name: 'find', model: 'Alias' },
      filter: {
        _and: [
          { id_process_record: { _eq: stock.auto?.version.version?.id } },
          { id_person: { _eq: this.idChileautos } },
          { id_process: { _eq: this.idProcessVersion } },
        ],
      },
      force: true,
    }))[0]

    const fields = {
        id: alias?.id,
        name: aliasChileautos,
        id_process_record: stock.auto?.version.version?.id,
        id_process: this.idProcessVersion,
        id_person: this.idChileautos,
    }

    if (fields?.id) {
      delete fields.id_process_record
      delete fields.id_process
      delete fields.id_person
    }
    await this.pushData({
      model: 'Alias',
      fields,
    })

    if (stock?.price) {
      this.businessRepublishById({ idStock: stock.id, idPlatform: this.idPlatform })
    }
  }

  async sendExtra () {
    const { formDataEquipment, stock, idProcess } = this

    const comfort = Array.isArray(formDataEquipment.comfort)
      ? formDataEquipment.comfort
      : []

    const security = Array.isArray(formDataEquipment.security)
      ? formDataEquipment.security
      : []

    const external = Array.isArray(formDataEquipment.external)
      ? formDataEquipment.external
      : []

    // Unificamos todo en un solo array
    const allEquipment = [...comfort, ...security, ...external]

    // Ejecutamos inserciones en paralelo
    await Promise.all(allEquipment.map(attribute =>
      this.insertUpdateDealAutoAttribute(stock.id, stock.viewDetails.idDeal, attribute)
    ))

    const dealAutoAttributes = await this.fetchData({
      query: { name: 'find', model: 'DealAutoAttribute' },
      filter: {
        _and: [
          { id_process: { _eq: idProcess } },
          { id_process_record: { _eq: stock.id } },
          { id_deal: { _eq: stock.viewDetails.idDeal } },
        ],
      },
      force: true,
    })

    // Filtramos los que no estén en allEquipment
    const toRemove = dealAutoAttributes?.filter(dbItem => {
      return !allEquipment.some(equip => equip.id === dbItem?.componentValue?.id)
    })

    // Eliminamos los que sobran
    if (toRemove.length) {
      await this.deleteDealAutoAttribute(toRemove)
    }
  }

  async insertUpdateDealAutoAttribute (id, idDeal, componentValue) {
    const { idProcess } = this
    const filter = {
      _and: [
        { id_component_value: { _eq: componentValue.id } },
        { id_component: { _eq: componentValue.component.id } },
        { id_process: { _eq: idProcess } },
        { id_process_record: { _eq: id } },
        { id_deal: { _eq: idDeal } }],
    }
    const dealAutoAttribute = await this.fetchData({
      query: { name: 'find', model: 'DealAutoAttribute' },
      filter,
      force: true,
    })

    if (dealAutoAttribute?.length) {
      return
    }
    await this.pushData({
      model: 'DealAutoAttribute',
      fields: {
        id_deal: idDeal,
        id_component_value: componentValue.id,
        id_component: componentValue.component.id,
        id_process: idProcess,
        id_process_record: id,
      },
    })
  }

  async deleteDealAutoAttribute (attributes) {
    await Promise.all(attributes.map(async att => {
      await this.removeData({ model: 'DealAutoAttribute', fields: { id: att.id } })
    }))
  }

  async sendStockMetadata () {
    const { formSocialNetworks, stock } = this

    const socialNetworks = Object.keys(formSocialNetworks).reduce((acc, key) => {
      acc[key] = { url: formSocialNetworks[key].url }
      return acc
    }, {})

    if (stock.metadata?.socialNetworks) {
      stock.metadata.socialNetworks = socialNetworks as SocialNetworks
    } else {
      stock.metadata = {
        ...stock.metadata,
        socialNetworks,
      }
    }
    await this.pushData({
      model: 'Stock',
      fields: {
        id: stock.id,
        metadata: stock.metadata,
      },
    })
  }

  async sendPhotos () {
    const { formData, fields, stock, componentKeysPhoto, componentKeysVideo, idProcess } = this

    await Promise.all(
      componentKeysPhoto.map(async field => {
        await this.handleFileType(formData[field].photo, fields[field], idProcess, stock.id, null)
      })
    )

    await Promise.all(
      componentKeysVideo.map(async field => {
        await this.handleFileType(formData[field].photo, fields[field], idProcess, stock.id, null)
      })
    )
  }

  loadingFile (flag) {
    this.isUploadingFile = flag
  }

  checkForDuplicatePhotos () {
    const seen = new Set()
    for (const key in this.formData) {
      const photos = this.formData[key].photo

      for (const photo of photos) {
        if (seen.has(photo.id)) {
          this.alert.open = true
          this.alert.title = `El campo ${key} tiene un archivo repetido.`
          this.$set(this.errorMessages, key, 'Archivo repetido detectado.')
        } else {
          seen.add(photo.id)
          this.$set(this.errorMessages, key, '')
        }
      }
    }
  }

  cleanFields () {
    this.alert.open = false
    this.alert.title = ''

    const key = Object.keys(this.errorMessages)
    const keysToClean = key.filter(key => this.errorMessages[key] !== '')

    keysToClean.forEach(key => {
      this.$set(this.errorMessages, key, '')
      this.formData[key].photo = []
    })
  }

  @Watch('fileParametersPhoto', { immediate: true, deep: true })
  @Watch('fileParametersVideo', { immediate: true, deep: true })
  async onFileParametersChange (fileParameters) {
    const newPhotos = []
    const { uid } = this

    const handleComponentChange = async parameter => {
      const photos = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: uid } },
            { id_file_parameter: { _eq: parameter.id } },
          ],
        },
        force: true,
      })

      if (photos.length) {
        newPhotos.push(...photos)
      }
    }

    for (const parameter of fileParameters) {
      await handleComponentChange(parameter)
    }

    for (const key of this.componentKeysPhoto) {
      const file = newPhotos.filter(photo => photo.parameter.description === key)

      if (file?.length) {
        this.formData[key].photo = file
      }
    }

    for (const key of this.componentKeysVideo) {
      const file = newPhotos.filter(photo => photo.parameter.description === key)

      if (file?.length) {
        this.formData[key].photo = file
      }
    }
  }

  @Watch('formData', { immediate: true, deep: true })
  onFormDataChange () {
    this.checkForDuplicatePhotos()
  }

  async getAutoGeneration (auto) {
    if (!auto?.generation?.id) return this.findAlternativesAttributes(auto)

    const generation = await this.fetchData({
      query: { name: 'fetch', params: { id: auto.generation.id }, model: 'Generation' },
    })

    if (generation?.attributes?.length) {
      return generation
    }

    return this.findAlternativesAttributes(auto)
  }

  async findAlternativesAttributes (auto) {
    if (!auto?.version?.version?.id || !auto?.version?.year?.id) return

    let getGenerations = await this.getGeneration(auto?.version?.version?.id, auto?.version?.year?.id)

    let generation = getGenerations?.length === 1 ? getGenerations[0] : getGenerations.find(generation => generation.id === auto.generation?.id)

    if (generation?.attributes?.length) {
      return generation
    }

    const attributes = await this.fetchData({
      query: {
        name: 'find',
        model: 'Attribute',
      },
      filter: {
        id_version_year: { _eq: auto.version.id },
      },
    })

    if (attributes?.length) {
      if (!generation) {
        generation = plainToInstance(Generation, {})
      }
      generation.attributes = attributes
      return generation
    }

    if (!auto?.generation?.sku) {
      return
    }

    let cont = 1
    while (cont <= 3 && !generation?.attributes?.length) {
      getGenerations = await this.getGeneration(auto?.version?.version?.id, auto?.version?.year?.id - cont)

      generation = getGenerations?.length === 1 ? getGenerations[0] : getGenerations.find(generation => generation.sku === auto.generation?.sku)

      if (generation?.attributes?.length) {
        return generation
      } else {
        cont++
      }
    }

    return generation
  }

  separateValues ({ generation, equipment }, equipmentName = 'Equipamiento comfort') {
    const genFilter = generation?.attributes?.filter(gen => gen.name === equipmentName)

    const matchingValues = []
    const nonMatchingValues = []

    const filterComponents = equipment.values.filter(equip => equip?.inspectionComponents?.length)

    for (const genAttr of genFilter) {
      const genValueId = genAttr.componentValue.id
      const isMatching = filterComponents.find(comfortValue => comfortValue.id === genValueId)

      if (isMatching) {
        matchingValues.push(isMatching)
      }
    }

    for (const value of filterComponents) {
      const valueId = value.id
      const isMatching = genFilter.some(genAttr => genAttr.componentValue.id === valueId)

      if (!isMatching) {
        nonMatchingValues.push(value)
      }
    }

    return {
      present: matchingValues,
      extra: nonMatchingValues.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase())),
    }
  }

  async getDealAutoAttribute (idComponent, idProcess, idProcessRecord) {
    return this.fetchData({
      query: { name: 'find', model: 'DealAutoAttribute' },
      filter: {
        _and: [
          { id_component: { _eq: idComponent } },
          { id_process: { _eq: idProcess } },
          { id_process_record: { _eq: idProcessRecord } },
        ],
      },
      force: true,
    })
  }
  }
